exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agency-js": () => import("./../../../src/pages/agency.js" /* webpackChunkName: "component---src-pages-agency-js" */),
  "component---src-pages-benefit-js": () => import("./../../../src/pages/benefit.js" /* webpackChunkName: "component---src-pages-benefit-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-maintenance-js": () => import("./../../../src/pages/maintenance.js" /* webpackChunkName: "component---src-pages-maintenance-js" */),
  "component---src-pages-nights-js": () => import("./../../../src/pages/nights.js" /* webpackChunkName: "component---src-pages-nights-js" */),
  "component---src-pages-queens-js": () => import("./../../../src/pages/queens.js" /* webpackChunkName: "component---src-pages-queens-js" */),
  "component---src-pages-rgpd-js": () => import("./../../../src/pages/rgpd.js" /* webpackChunkName: "component---src-pages-rgpd-js" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-queen-jsx": () => import("./../../../src/templates/queen.jsx" /* webpackChunkName: "component---src-templates-queen-jsx" */)
}

